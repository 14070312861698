import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  Renderer,
  ViewChild } from '@angular/core';

@Component({
  selector: 'mz-parallax',
  template: `<div #parallaxContainer class="parallax-container">
  <div #parallax class="parallax">
    <ng-content></ng-content>
  </div>
</div>`,
  styles: [``],
})
export class MzParallaxComponent implements AfterViewInit {
  @Input() height: number;

  @ViewChild('parallax') parallax: ElementRef;
  @ViewChild('parallaxContainer') parallaxContainer: ElementRef;

  constructor(public renderer: Renderer) { }

  ngAfterViewInit(): void {
    this.renderer.setElementStyle(this.parallaxContainer.nativeElement, 'height', isNaN(this.height) ? '500px' : this.height + 'px');
    this.renderer.invokeElementMethod($(this.parallax.nativeElement), 'parallax');
  }
}
