import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChild,
  Directive,
  ElementRef,
  Input,
  Renderer,
  ViewChild,
} from '@angular/core';

import { MzSidenavCollapsibleHeaderComponent } from './sidenav-collapsible-header/sidenav-collapsible-header.component';

@Component({
  selector: 'mz-sidenav-collapsible',
  template: `<li>
  <ul #collapsible class="collapsible collapsible-accordion">
    <li>
      <ng-content select="mz-sidenav-collapsible-header"></ng-content>
      <div class="collapsible-body">
        <ul>
          <ng-content select="mz-sidenav-collapsible-content"></ng-content>
        </ul>
      </div>
    </li>
  </ul>
</li>`,
  styles: [`:host /deep/ .collapsible-header{padding:0 32px}:host /deep/ .collapsible-header i{color:rgba(0,0,0,.54)}:host /deep/ .collapsible-body li a{font-weight:initial}`],
})
export class MzSidenavCollapsibleComponent implements AfterViewInit {
  @Input() onClose: Function;
  @Input() onOpen: Function;

  @ViewChild('collapsible') collapsible: ElementRef;
  @ContentChild(MzSidenavCollapsibleHeaderComponent) header: MzSidenavCollapsibleHeaderComponent;

  constructor(
    public changeDetectorRef: ChangeDetectorRef,
    public renderer: Renderer,
  ) { }

  ngAfterViewInit() {
    this.initCollapsible();
  }

  initCollapsible() {
    const options: Materialize.CollapsibleOptions = {
      accordion: false,
      onClose: this.onClose,
      onOpen: this.onOpen,
    };

    // need setTimeout otherwise loading directly on the page cause an error
    setTimeout(() => this.renderer.invokeElementMethod($(this.collapsible.nativeElement), 'collapsible', [options]));

    this.changeDetectorRef.detectChanges();
  }
}

// Declare the tags to avoid error: '<mz-sidenav-collapsible-x>' is not a known element
// https://github.com/angular/angular/issues/11251
// tslint:disable: directive-selector
@Directive({ selector: 'mz-sidenav-collapsible-content' }) export class MzSidenavCollapsibleContentDirective { }
