import { Component } from '@angular/core';

@Component({
  selector: 'mz-sidenav-divider',
  template: `<li>
  <div class="divider"></div>
</li>`,
  styles: [`.divider{margin-bottom:8px}`],
})
export class MzSidenavDividerComponent { }
