import { Component, Input } from '@angular/core';

@Component({
  selector: 'mz-spinner',
  template: ` <div class="preloader-wrapper active {{ size }}">

    <div
      class="spinner-layer"
      [class.spinner-red-only]="color === 'red'"
      [class.spinner-green-only]="color === 'green'"
      [class.spinner-blue-only]="color === 'blue'"
      [class.spinner-yellow-only]="color === 'yellow'">

      <div class="circle-clipper left">
        <div class="circle"></div>
      </div>

      <div class="gap-patch">
        <div class="circle"></div>
      </div>

      <div class="circle-clipper right">
        <div class="circle"></div>
      </div>
    </div>
  </div>`,
  styles: [``],
})
export class MzSpinnerComponent {
  @Input() color: string;
  @Input() size: string; // small, medium, big
}
