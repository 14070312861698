import {
  AfterViewInit,
  Component,
  ContentChildren,
  ElementRef,
  HostBinding,
  Input,
  NgZone,
  QueryList,
  ViewChild,
} from '@angular/core';

import { MzTabItemComponent } from './tab-item/tab-item.component';

@Component({
  selector: 'mz-tab',
  template: `<ul #tabs
  class="tabs"
  [class.tabs-fixed-width]="fixedTabWidth">
  <li class="tab" [class.disabled]="tabItem.disabled" *ngFor="let tabItem of tabItems.toArray()">
    <a [class.active]="tabItem.active"
      href="{{ tabItem.href ? tabItem.href : '#' + tabItem.link }}" target="{{ tabItem.target }}">
      {{ tabItem.label }}
    </a>
  </li>
</ul>
<div>
  <ng-content select="mz-tab-item"></ng-content>
</div>`,
  styles: [``],
})
export class MzTabComponent implements AfterViewInit {
  @Input() fixedTabWidth: boolean;
  @Input() onShow: Function;
  @Input() responsiveThreshold: number;
  @Input() swipeable: boolean;

  @ViewChild('tabs') tabs: ElementRef;
  @ContentChildren(MzTabItemComponent) tabItems: QueryList<MzTabItemComponent>;

  ngAfterViewInit(): void {
    this.initTabs();
  }

  initTabs() {
    const options: Materialize.TabOptions = {
      onShow: this.onShow,
      responsiveThreshold: this.responsiveThreshold,
      swipeable: this.swipeable,
    };

    $(this.tabs.nativeElement).tabs(options);
  }

  selectTab(tabItemId: string) {
    $(this.tabs.nativeElement).tabs('select_tab', tabItemId);
  }
}
