import { Component, Input } from '@angular/core';

@Component({
  selector: 'mz-input-container',
  template: `<div
  class="input-field"
  [class.inline]="inline"
>
  <ng-content></ng-content>
</div>`,
  styles: [``],
})
export class MzInputContainerComponent {
  @Input() inline: boolean;
}
