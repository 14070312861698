import { Component, ContentChild } from '@angular/core';

import { MzSwitchDirective } from '../switch.directive';

@Component({
  selector: 'mz-switch-container',
  template: `<div class="switch">
  <label>
    <span class="off">{{ mzSwitchDirective?.off }}</span>
    <ng-content></ng-content>
    <span class="lever"></span>
    <span class="on">{{ mzSwitchDirective?.on }}</span>
  </label>
</div>`,
  styles: [``],
})
export class MzSwitchContainerComponent {
  @ContentChild(MzSwitchDirective) mzSwitchDirective: MzSwitchDirective;
}
