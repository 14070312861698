import { Component } from '@angular/core';

import { MzRemoveComponentHost } from '../../../shared/remove-component-host/remove-component-host';

@Component({
  selector: 'mz-sidenav-collapsible-header',
  template: `<a class="collapsible-header waves-effect"><ng-content></ng-content></a>`,
  styles: [``],
})
export class MzSidenavCollapsibleHeaderComponent extends MzRemoveComponentHost { }
