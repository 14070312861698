import { Component } from '@angular/core';

@Component({
  selector: 'mz-checkbox-container',
  template: `<p class="checkbox-field">
  <ng-content></ng-content>
</p>`,
  styles: [``],
})
export class MzCheckboxContainerComponent { }
