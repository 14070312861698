import { AfterViewInit, Component, ElementRef, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'mz-feature-discovery',
  template: `<div class="tap-target-content">
  <ng-content></ng-content>
</div>
`,
  styles: [`:host{display:block}`],
})
export class MzFeatureDiscoveryComponent implements AfterViewInit {
  @HostBinding('class.tap-target')
  targetClass = true;

  @HostBinding('attr.data-activates')
  @Input()
  targetId: string;

  private target: JQuery<Element>;

  constructor(
    private elementRef: ElementRef,
  ) { }

  ngAfterViewInit() {
    this.target = $(this.elementRef.nativeElement);
  }

  close() {
    this.target.tapTarget('close');
  }

  open() {
    this.target.tapTarget('open');
  }
}
