import { Component, Input } from '@angular/core';

@Component({
  selector: 'mz-navbar-item-container',
  template: `<ul id="nav-mobile" class="{{ align }}">
  <ng-content></ng-content>
</ul>`,
  styles: [``],
})
export class MzNavbarItemContainerComponent {
  @Input() align: string;
}
