import { Component, Input } from '@angular/core';

@Component({
  selector: 'mz-textarea-container',
  template: `<div
  class="input-field"
  [class.inline]="inline"
>
  <ng-content></ng-content>
</div>`,
  styles: [`:host /deep/ textarea{display:block}.input-field:not(.inline){display:block}`],
})
export class MzTextareaContainerComponent {
  @Input() inline: boolean;
}
