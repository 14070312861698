import { Component, Input } from '@angular/core';

@Component({
  selector: 'mz-navbar',
  template: `<nav>
  <div class="nav-wrapper {{ navbarClass }}">
    <ng-content></ng-content>
  </div>
</nav>`,
  styles: [`.nav-wrapper /deep/ .btn i{line-height:inherit}`],
})
export class MzNavbarComponent {
  @Input() navbarClass: string;
}
