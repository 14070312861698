import { Component } from '@angular/core';

@Component({
  selector: 'mz-dropdown-item',
  template: `<li>
  <ng-content></ng-content>
</li>`,
  styles: [``],
})
export class MzDropdownItemComponent { }
