import { Component, Input } from '@angular/core';

@Component({
  selector: 'mz-badge',
  template: `<span #badge
  class="badge {{ badgeClass }}"
  [class.new]="new || !!badgeClass"
  [attr.data-badge-caption]="caption">
  {{ value }}
</span>
`,
  styles: [``],
})
export class MzBadgeComponent {
  @Input() badgeClass: string;
  @Input() caption: string;
  @Input() new: boolean;
  @Input() value: number;
}
