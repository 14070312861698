import { Component, Input } from '@angular/core';

@Component({
  selector: 'mz-progress',
  template: `<div class="progress {{ backgroundClass }}">

  <div
    class="progress-bar {{ progressClass }}"
    [class.determinate]="percentage != null"
    [class.indeterminate]="percentage == null"
    [style.width.%]="percentage">
  </div>
</div>`,
  styles: [``],
})
export class MzProgressComponent {
  @Input() backgroundClass: string;
  @Input() percentage: number;
  @Input() progressClass: string;
}
