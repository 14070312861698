import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'mz-pagination-page-button',
  template: `<li [class.active]="active"
  [class.disabled]="disabled"
  [class.waves-effect]="!active && !disabled">
  <ng-content></ng-content>
</li>`,
  styles: [``],
})
export class MzPaginationPageButtonComponent {
  @Input() active: boolean;
  @Input() disabled: boolean;
}
