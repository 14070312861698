import { Component } from '@angular/core';

@Component({
  selector: 'mz-sidenav-header',
  template: `<li class="sidenav-header">
  <ng-content></ng-content>
</li>`,
  styles: [`.sidenav-header{margin-bottom:8px}`],
})
export class MzSidenavHeaderComponent { }
