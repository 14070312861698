import { Component } from '@angular/core';

@Component({
  selector: 'mz-collection-header',
  template: `<div class="collection-header">
  <ng-content></ng-content>
</div>`,
  styles: [``],
})
export class MzCollectionHeaderComponent { }
