import { Component, Input } from '@angular/core';

@Component({
  selector: 'mz-timepicker-container',
  template: `<div
  class="input-field"
  [class.inline]="inline"
>
  <ng-content></ng-content>
</div>`,
  styles: [``],
})
export class MzTimepickerContainerComponent {
  @Input() inline: boolean;
}
