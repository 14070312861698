import {
  AfterViewInit,
  Component,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer,
  ViewChild,
} from '@angular/core';

import { HandlePropChanges } from '../shared/index';

@Component({
  selector: 'mz-modal',
  template: `<div #modal
  class="modal"
  [class.modal-fixed-footer]="fixedFooter"
  [class.bottom-sheet]="bottomSheet"
  [class.modal-fullscreen]="fullscreen"
>
  <div class="modal-content">
    <ng-content select="mz-modal-header"></ng-content>
    <div>
      <ng-content select="mz-modal-content"></ng-content>
    </div>
  </div>
  <div class="modal-footer">
    <ng-content select="mz-modal-footer"></ng-content>
  </div>
</div>
`,
  styles: [`.modal:not(.bottom-sheet).modal-fullscreen{top:12px!important;margin:0 auto;width:calc(100% - 24px);height:calc(100% - 24px);max-height:none}.modal.bottom-sheet.modal-fullscreen{height:100%;max-height:none}/deep/ mz-modal-header h5,/deep/ mz-modal-header h6{margin-top:0}`],
})
export class MzModalComponent extends HandlePropChanges implements OnInit, AfterViewInit {
  @Input() bottomSheet: boolean;
  @Input() fixedFooter: boolean;
  @Input() fullscreen: boolean;
  @Input() options: Materialize.ModalOptions;
  @Output() close = new EventEmitter<void>();
  @ViewChild('modal') modalElementRef: ElementRef;

  modalElement: JQuery;

  constructor(public renderer: Renderer) {
    super();
  }

  ngOnInit() {
    this.initHandlers();
    this.initElements();
    this.handleProperties();
  }

  ngAfterViewInit() {
    this.initModal();
  }

  initElements() {
    this.modalElement = $(this.modalElementRef.nativeElement);
  }

  initHandlers() {
    this.handlers = {
       options: () => this.handleOptions(),
    };
  }

  initModal() {
    this.renderer.invokeElementMethod(this.modalElement, 'modal', [this.options]);
  }

  handleProperties() {
    super.executePropHandlers();
  }

  handleOptions() {
    // extend complete function to emit close event on callback return
    const originalCompleteFn = this.options && this.options.complete || (() => {});
    this.options = Object.assign({}, this.options, {
      complete: () => {
        originalCompleteFn();
        this.close.emit();
      },
    });
  }

  openModal() {
    this.renderer.invokeElementMethod(this.modalElement, 'modal', ['open']);
  }

  closeModal() {
    this.renderer.invokeElementMethod(this.modalElement, 'modal', ['close']);
  }
}

// Declare the tags to avoid error: '<mz-modal-x>' is not a known element
// https://github.com/angular/angular/issues/11251
// tslint:disable: directive-selector
@Directive({ selector: 'mz-modal-header' }) export class MzModalHeaderDirective { }
@Directive({ selector: 'mz-modal-content' }) export class MzModalContentDirective { }
@Directive({ selector: 'mz-modal-footer' }) export class MzModalFooterDirective { }
