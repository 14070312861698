import { Component } from '@angular/core';

@Component({
  selector: 'mz-sidenav-subheader',
  template: `<li>
  <a class="subheader">
    <ng-content></ng-content>
  </a>
</li>`,
  styles: [``],
})
export class MzSidenavSubheaderComponent { }
