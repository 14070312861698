import { Component } from '@angular/core';

@Component({
  selector: 'mz-radio-button-container',
  template: `<p class="radio-button-field">
  <ng-content></ng-content>
</p>`,
  styles: [``],
})
export class MzRadioButtonContainerComponent { }
